import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "class101-ui"
    }}>{`Class101 UI`}</h1>
    <p>{`Class101 UI는 리액트 프론트엔드 개발을 더 빠르고 쉽게 만들어줍니다. 또한 클래스101 프로젝트들을 위해 만들어졌습니다.`}</p>
    <h2 {...{
      "id": "quick-start"
    }}>{`Quick Start`}</h2>
    <h3 {...{
      "id": "install"
    }}>{`Install`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-console"
      }}>{`npm install --save @class101/ui
`}</code></pre>
    <h3 {...{
      "id": "usage"
    }}>{`Usage`}</h3>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`NOTE`}</strong></p>
      <ul parentName="blockquote">
        <li parentName="ul">{`v6부터는 HTMLAttributes 속성들은 {HTMLElement}Attributes 속성을 통해 전달합니다.`}</li>
      </ul>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React, { Component } from 'react';

import { Button } from '@class101/ui';

class Example extends Component {
  render() {
    return <Button>Hello, world!</Button>;
  }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      